import React, { useState } from "react";
import styled from "styled-components";
import {
  InfoCircleOutlined, SettingOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Switch, Select } from "antd";
import DrawerComp from "../drawer";
import LogoApp from "../../assets/image/logo.png";
import IconVN from "../../assets/image/vn.png";
import IconEN from "../../assets/image/en.png";
import DarkIcon from "../../assets/icon/darkmode";
import { lightTheme, darkTheme } from "../../constants/theme/theme";
import { useTranslation } from "react-i18next";
import "./index.css";

const { Option } = Select;

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [showDraw, setShowDraw] = useState(false);
  const currentTheme = props.currentTheme;

  const handleOnClickOptions = () => {
    setShowDraw(!showDraw);
  };

  const handleCloseDraw = () => {
    setShowDraw(false);
  };

  // Action switch change dark mode
  const onChangeSwitchMode = () => {
    if (props.onChangeTheme) {
      props.onChangeTheme();
    }
  };

  // Action select item change language
  const handleChangeLanguage = (val) => {
    i18n.changeLanguage(val)
  }

  const renderChildrenDrawer = () => {
    return (
      <div>
        <ItemDrawer>
          <BoxIconItemDrawer>
          <div style={{marginLeft: 6, fontSize: '13px', fontWeight: 'bold'}}>{t('LANGUAGE')}</div>
          </BoxIconItemDrawer>
          <Select defaultValue={'vi'} style={{ marginRight: 5 }} onChange={handleChangeLanguage}>
            <Option key={'vi'}>
              <span role="img" aria-label="USA">
                <IconLangue src={IconVN}/>
              </span>{t('VIETNAM')}
            </Option>
            <Option key={'en'}>
              <span role="img" aria-label="USA">
                <IconLangue src={IconEN}/>
              </span>
              {t('ENGLISH')}
            </Option>
          </Select>
        </ItemDrawer>
        <LineSpace />
        <ItemDrawer>
          <BoxIconItemDrawer>
            <BtnChangeTheme>
              <DarkIcon
                color={
                  currentTheme === "light"
                    ? lightTheme.colorIcon
                    : darkTheme.colorIcon
                }
              />
            </BtnChangeTheme>
            <div>{t('DARK_MODE')}</div>
          </BoxIconItemDrawer>
          <Switch
            size="small"
            style={{ marginRight: 5 }}
            defaultChecked={false}
            onChange={onChangeSwitchMode}
          />
        </ItemDrawer>
      </div>
    );
  };
  return (
    <Container>
      <FlexBoxHeader>
        <AvartarBox onClick={() => history.push("/")} src={`${LogoApp}`} />
        <NameApp onClick={() => history.push("/")}>{t('NAME_APP')}</NameApp>
      </FlexBoxHeader>
      <FlexBoxHeader style={{ justifyContent: "flex-end" }}>
        <BtnOptions onClick={handleOnClickOptions}>
          <SettingOutlined
            style={{
              color: "white",
              fontSize: 22,
            }}
          />
        </BtnOptions>
        <BtnInfo onClick={() => history.push("/info")}>
          <InfoCircleOutlined
            style={{
              color: "white",
              fontSize: 24,
            }}
          />
        </BtnInfo>
      </FlexBoxHeader>
      <DrawerComp
        isShow={showDraw}
        handleClose={handleCloseDraw}
        backGround={
          currentTheme === "light"
            ? lightTheme.drawerBackground
            : darkTheme.drawerBackground
        }
        children={renderChildrenDrawer()}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  box-shadow: rgba(0, 0, 0, 0.27) 0px 3px 7px 0px;
  background: ${({ theme }) => theme.headerBackground};
  z-index: 999998;
`;

const BtnChangeTheme = styled.a`
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.backgroundIcon};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 5px;
  padding: 10px;
`;

const BtnOptions = styled.a`
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: ${({ theme }) => theme.headerBackground};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  // margin-right: 20px;
  margin-left: 15px;
  padding: 10px;
`;
const BtnInfo = styled.a`
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: ${({ theme }) => theme.headerBackground};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 15px;
  padding: 10px;
`;
const FlexBoxHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const AvartarBox = styled.img`
  width: 38px;
  height: 38px;
  object-fit: contain;
  margin-right: 15px;
  margin-left: 15px;
  object-fit: cover;
`;

const IconLangue = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 5px;
  // margin-left: 15px;
  object-fit: cover;
`;


const NameApp = styled.div`
  display: flex;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  @media (max-width: 800px) {
    font-size: 12px;
    line-height: 16px;
  }
`;


const ItemDrawer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.drawerItemColor};
  border-radius: 5px;
  margin-bottom: 3px;
  :hover {
    background-color: ${({ theme }) => theme.drawerItemColorHover};
  }
`;

const LineSpace = styled.hr`
  margin-bottom: 8px;
  margin-top: 8px;
  background: ${({ theme }) => theme.borderColor};
  border-width: 0;
  color: ${({ theme }) => theme.borderColor};
  height: 1px;
`;

const BoxIconItemDrawer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Header;
