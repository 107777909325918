import { DetailMapConstant } from "../../constants/actions";

const initialState = {
  dataParams: null
};

const DetailMap = (state = initialState, action) => {
  switch (action.type) {
    case DetailMapConstant.DETAIL_MAP_SET_PARAMS:
      return {...state};
    case DetailMapConstant.DETAIL_MAP_SET_PARAMS_DONE:
      return {...state, dataParams: action.res };
    default:
      return state;
  }
};

export default DetailMap;
