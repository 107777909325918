import { put , takeEvery } from "redux-saga/effects";
import { setParamsDone } from "../actions";
import { DetailMapConstant } from "../../constants/actions";
import * as _ from 'lodash'


function* detailMap(action) {
  const dataParams = _.get(action, 'params', {})
  // console.log('detailMap==>', action)
  const localStorageParamsDefault = localStorage.getItem('paramsDefault');
  if (!_.isEmpty(localStorageParamsDefault)) {
    localStorage.removeItem('paramsDefault');
  }
  localStorage.setItem('paramsDefault',JSON.stringify(dataParams));
  yield put(setParamsDone(dataParams));
}

export default function* watchDetailMap() {
  yield takeEvery(DetailMapConstant.DETAIL_MAP_SET_PARAMS, detailMap);
}


