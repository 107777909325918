import { MasterConstant} from "../../constants/actions";

export const fetchData = (params) => ({
  type: MasterConstant.MASTER_GET_DATA,
  params,
});

export const fetchDataSuccess = (res) => ({
  type: MasterConstant.MASTER_GET_DATA_SUCCESS,
  res,
});

export const fetchDataError = (error) => ({
  type: MasterConstant.MASTER_GET_DATA_ERROR,
  error,
});
