import React from "react";

const IconWQI = (props) => {
  const {color, width, height} = props;
  return (
    <div style={{ display: "flex", fill: color }}>
      <svg width={width} height={height} viewBox="0 0 39 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7623 1.23467C32.6528 11.6172 38.6965 20.6829 38.6965 28.6517C38.6965 39.9368 29.8887 48 19.3483 48C8.80788 48 0 39.9368 0 28.6517C0 20.6829 6.04377 11.6172 17.9343 1.23467L19.3483 0L20.7623 1.23467ZM4.29935 28.6516C4.29935 37.4731 11.1016 43.7003 19.348 43.7003C27.5944 43.7003 34.3967 37.4731 34.3967 28.6516C34.3967 22.5263 29.4379 14.8207 19.348 5.72732C9.25813 14.8207 4.29935 22.5263 4.29935 28.6516ZM19.3482 37.2496V41.5492C26.4721 41.5492 32.2471 35.7742 32.2471 28.6503H27.9475C27.9475 33.3996 24.0975 37.2496 19.3482 37.2496Z" fill={color}/>
      </svg>
    </div>
  );
};

export default IconWQI;
