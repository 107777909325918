import React  from "react";
import styled from "styled-components";
import "./index.css";


const SkeletonMasterContainer = (props) => {
    const {screenHeight} = props

    return (
        <BoxContainer height={`${screenHeight}px`}>
            <ItemBox key={'skeleton_master_1'}>
                <div className="skeleton-box" style={{width:'100%', height: '100%', borderRadius:'16px'}}></div>
            </ItemBox>
            <ItemBox key={'skeleton_master_2'}>
                <div className="skeleton-box" style={{width:'100%', height: '100%', borderRadius:'16px'}}></div>
            </ItemBox>
            <ItemBox key={'skeleton_master_3'}>
                <div className="skeleton-box" style={{width:'100%', height: '100%', borderRadius:'16px'}}></div>
            </ItemBox>
            <ItemBox key={'skeleton_master_4'}>
                <div className="skeleton-box" style={{width:'100%', height: '100%', borderRadius:'16px'}}></div>
            </ItemBox>
        </BoxContainer>
    )
}
const BoxContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: ${props => props.height};
`

const ItemBox = styled.div`
  width: calc(((100%) - 40px)/2);
  background-color: ${({ theme }) => theme.itemMaster};
  margin: 10px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  :hover {
    box-shadow: ${({ theme }) => theme.hoverItemMaster};
  }
  @media (max-width: 800px) {
    flex: 100%;
  }
`

export default SkeletonMasterContainer