import React, { useRef, useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { connect } from "react-redux";
import * as _ from 'lodash'
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SkeletonDetailMap from '../../component/skeleton/detail-map'
import {actionPost} from '../../utils/axios-action'
import ListStation from './list-station'
import { MapComponent } from './map'
import IconAQI from '../../assets/icon/AQI'
import IconWQI from '../../assets/icon/WQI'
import IconKTTV from '../../assets/icon/KTTV'
import IconCBL from '../../assets/icon/CBL'
import IconMap from '../../assets/icon/Map'
import { lightTheme, darkTheme } from "../../constants/theme/theme";


const DetailMap = (props) => {
    const { t } = useTranslation();
    const divMapRef = useRef();
    const currentTheme = props.currentTheme;
    const [heightMap, setHeightMap] = useState()
    const [dataMap, setDataMap] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const {type} = useParams()
    const dataMaster =_.keyBy(props.data, 'code');
    // Get api list station
    const apiFetch = _.get(dataMaster[`${type}`], 'links.list', null)
    // Get api detail by station
    const apiDetail = _.get(dataMaster[`${type}`], 'links.detail', '#')
    // Get default API from localStorage
    const loacalStorageDataMaster = localStorage.getItem('dataMaster')


    useEffect(() => {
        setIsLoading(true)
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
          const apiURL = renderApiURL()
          const res = await actionPost(apiURL, {})
          if(res.success) {
              setDataMap(res.data)
              setIsLoading(false)
              setHeightMap(divMapRef.current.clientHeight)
          }
        } catch (error) {
          console.log('error', error)
          setIsLoading(false)
          setHeightMap(divMapRef.current.clientHeight)
        }
        
    }

    // Action check and return defautl URL api from localStorage
    const renderApiURL = () => {
        if (!_.isEmpty(apiFetch)) {
            return apiFetch
        } else {
            const dataMasterDefautl = JSON.parse(loacalStorageDataMaster)
            const obj = _.keyBy(dataMasterDefautl, 'code')
            return _.get(obj[`${type}`], 'links.list', '#')
        } 
    }

    const renderIconTitle = (key) => {
        switch (key) {
          case 'AQI':
            return <IconAQI color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"32"} height={"32"}/>
          case 'WQI':
            return <IconWQI color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"26"} height={"26"}/>
          case 'KKTV':
            return <IconKTTV color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"26"} height={"26"}/>
          case 'CBL':
            return <IconCBL color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"26"} height={"26"}/>
          default:
            break;
        }
      }

    return isLoading ? (<SkeletonDetailMap/>) : (
        <BoxContainer ref={divMapRef}>
            <BoxInfoContainer>
                <ItemBoxTitle>
                    {renderIconTitle(type)}
                    <Title>{_.get(dataMaster[`${type}`], 'name', '') }</Title>
                </ItemBoxTitle>
                <ItemBox>
                    {heightMap && dataMap && <ListStation heightScreen={heightMap} type={type} dataMap={dataMap} apiDetail={apiDetail} />}
                </ItemBox>
            </BoxInfoContainer>
            <BoxMapContainer>
                <ItemBoxTitle>
                    <IconMap/>
                    <Title>{t('MAP')}</Title>
                </ItemBoxTitle>
                <ItemBox>
                    {heightMap && dataMap && <MapComponent heightMap={heightMap} dataMap={dataMap} />}
                </ItemBox>
            </BoxMapContainer>
        </BoxContainer>
    )
}

const BoxContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    padding: 5px;
    height: calc((100%) - 230px);
`
const BoxInfoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    margin: 5px;
    flex: 35%;
    background-color: ${({ theme }) => theme.itemMaster};
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    :hover {
        box-shadow: ${({ theme }) => theme.hoverItemMaster};
    }
    @media (max-width: 800px) {
        flex: 100%;
    }
`
const BoxMapContainer = styled.div`
    padding: 10px;
    margin: 5px;
    flex: 55%;
    background-color: ${({ theme }) => theme.itemMaster};
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    :hover {
        box-shadow: ${({ theme }) => theme.hoverItemMaster};
    }
    @media (max-width: 800px) {
        flex: 100%;
    }
`

const ItemBoxTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
//   justify-content: space-between;
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #818181;
`

const ItemBox = styled.div`
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%
`

const Title = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.text};
`

const mapStateToProps = (state) => {
    return {
      ...state.dataMaster
    };
  };

export default connect(mapStateToProps)(DetailMap);

