import React from "react";
import styled from "styled-components";
import LogoMap from "../../assets/image/map-icon.png";

const IconMap = (props) => {
  return (
    <MapBox src={`${LogoMap}`} />
  );
};

const MapBox = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-fit: cover;
  margin-bottom: 10px;
`;

export default IconMap;
