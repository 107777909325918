import React , { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as _ from 'lodash'
import SkeletonDetailTable from '../../component/skeleton/detail-table'
import {actionPost} from '../../utils/axios-action'
import TableData from './table-data'
import iconMarker from '../../assets/image/marker-map.png'

const DetailTableContainer = (props) => {
    const {dataParams} = props
    const [dataTable, setDataTable] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    // Get default Params from localStorage
    const loacalStorageParamsDefault = localStorage.getItem('paramsDefault')

    useEffect(() => {
      setIsLoading(true)
      fetchData()
    }, [])

    const fetchData = async () => {
      try {
        const params = renderParamsFetch()
        const res = await actionPost(_.get(params, 'api'), params)
        if(res.success) {
          setDataTable(res.data)
          setIsLoading(false)
        }
      } catch (error) {
          console.log('error', error)
          setIsLoading(false)
      }
    }

    // Action check and return default params from localStorage
    const renderParamsFetch = () => {
      if(!_.isEmpty(dataParams)){
        return {...dataParams}
      } else {
        const paramsDefault = JSON.parse(loacalStorageParamsDefault)
        return !_.isEmpty(paramsDefault) ? {...paramsDefault} : {}
      }
    }

    
    return isLoading ? (<SkeletonDetailTable/>) : (
        <BoxContainer>
            <BoxHeader>
                <BoxIconMarker>
                    <IconBox>
                        <IconMarker src={iconMarker}/>
                    </IconBox>
                    <StationName>{_.get(renderParamsFetch(), 'station_name', '')}</StationName>
                </BoxIconMarker>
            </BoxHeader>
            {/* <LineSpace /> */}
            <TableData data={dataTable}/>
        </BoxContainer>
    )
}

const BoxContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px;
    margin-bottom: 30px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    height: calc((100%) - 110px);
    background-color: ${({ theme }) => theme.backgroundTableDetail};
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    :hover {
        box-shadow: ${({ theme }) => theme.hoverItemMaster};
    }
`

const BoxHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
//   background-color: ${({ theme }) => theme.backgroundTableDetailHeader};
  border-radius: 5px;
//   margin-bottom: 10px;
`;

const BoxIconMarker = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconBox = styled.a`
  width: 33px;
  height: 33px;
  object-fit: contain;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.backgroundIconMarker};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 5px;
  padding: 5px;
`;

const IconMarker = styled.img`
    width: 30px;
    height: 30px;
`

const StationName = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
`

const LineSpace = styled.hr`
  margin-bottom: 8px;
  margin-top: 8px;
  background: ${({ theme }) => theme.borderColor};
  border-width: 0;
  color: ${({ theme }) => theme.borderColor};
  height: 1px;
  width: 100%;
`;


const mapStateToProps = (state) => {
  return {
    ...state.paramsDetail
  };
};

export default connect(mapStateToProps) (DetailTableContainer);

