import { put , takeEvery } from "redux-saga/effects";
import { fetchDataSuccess, fetchDataError } from "../actions";
import { MasterConstant } from "../../constants/actions";
import {actionGet} from '../../utils/axios-action'
import * as _ from 'lodash'


const URL_DATA_MASTER = `${process.env.REACT_APP_API_URL}/eos/public_sites/call/json/dashboard`

function* master(action) {
  try {
    const { params } = action;
    // const res = yield fakeApi(params);
    const res = yield actionGet(URL_DATA_MASTER, params)
    // Action set data master into localStorage;
    const localStorageMasterData = localStorage.getItem('dataMaster');
    if (!_.isEmpty(localStorageMasterData)) {
      localStorage.removeItem('dataMaster');
    }
    if (res.success) {
      localStorage.setItem('dataMaster',JSON.stringify(res.data));
      //JSON.parse(dataMaster)
      yield put(fetchDataSuccess(res.data));
    } else {
      yield put(fetchDataError("Fetch data fail"));
    }
  } catch (error) {
    yield put(fetchDataError(error));
  }
}

// const fakeApi = (params) => {
//   return {success: true, data: dataFake}
// }

export default function* watchMaster() {
  yield takeEvery(MasterConstant.MASTER_GET_DATA, master);
}


