import axios from 'axios'


const getHeaders = () => {
    const headers = {
      Accept: 'application/json'
    }
    return headers
  }
  

export function actionPost (url, body) {
    let attributes = Object.assign(
        {
          cache: true,
          headers: getHeaders()
        }
    )
    return new Promise((resolve, reject) => {
        axios
        .post(url, body, attributes)
        .then(res => {
        resolve(res.data)
        })
        .catch(e => reject(e))
    })
    
}


export function actionGet (url, params) {
    let attributes = Object.assign(
        {
          cache: true,
          headers: getHeaders()
        }
    )
    return new Promise((resolve, reject) => {
        axios
        .get(url, params, attributes)
        .then(res => {
        resolve(res.data)
        })
        .catch(e => reject(e))
    })
}