import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import { lightTheme, darkTheme } from "../../constants/theme/theme";
import { GlobalStyles } from "../../constants/theme/global";
import * as masterAction from '../../redux/actions'
import Header from "../../component/header";
import Master from "../master";
import DetailMapContainer from '../detail-map'
import DetailTableContainer from '../detail-table'
import InfoContainer from '../info'
const Main = (props) => {
  const [theme, setTheme] = useState("light");
  const handleChangeTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  useEffect(() => {
    props.actionFetchDataMaster({});
    return () => {
      /* componentWillUnmount code */
      console.log('componentWillUnmount==>')
    }
  },[])

  

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <Header
            currentTheme={theme}
            onChangeTheme={handleChangeTheme}
        />
        <Route exact path="/">
          <Master currentTheme={theme} />
        </Route>
        <Route exact path="/detail-map/:type?">
          <DetailMapContainer currentTheme={theme} />
        </Route>
        <Route exact path="/detail-table/:id">
          <DetailTableContainer currentTheme={theme} />
        </Route>
        <Route exact path="/info">
          <InfoContainer currentTheme={theme} />
        </Route>
      </>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    rootState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionFetchDataMaster: (params) => dispatch(masterAction.fetchData(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
