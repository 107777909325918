import React  from "react";
import styled from "styled-components";
import "./index.css";


const SkeletonChartItem = (props) => {
  const {screenHeight} = props
    return (
        <ItemBox height={`${(screenHeight-45)/2}px`} key={'skeleton_master_1'}>
            {/* <div className="skeleton-box" style={{width:'100%', height: '100%', borderRadius:'16px'}}></div> */}
            <SkeletonBox className="skeleton-box"></SkeletonBox>
        </ItemBox>
    )
}

const ItemBox = styled.div`
  width: calc(((100%) - 40px)/2);
  height: ${props => props.height};
  background-color: ${({ theme }) => theme.itemMaster};
  margin: 10px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  @media (max-width: 800px) {
    flex: 100%;
  }
`

const SkeletonBox = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgroundSkeleton};
`

export default SkeletonChartItem