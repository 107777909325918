import React from "react";
import { Drawer } from "antd";
import "./index.css";

const DrawerComp = (props) => {
  const { isShow, backGround, children } = props;

  const closeDrawer = () => {
    if (props.handleClose) props.handleClose();
  };
  return (
    <Drawer
      placement={"right"}
      closable={false}
      onClose={closeDrawer}
      visible={isShow}
      key={"placement"}
      width={250}
      style={{ marginTop: "60px" }}
      drawerStyle={{ backgroundColor: backGround }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerComp;
