export const lightTheme = {
  body: "#F3F3F3", //"#F0F2F5",
  text: "#37474F",
  headerBackground: "linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #1976D2, #1976D2);",
  btnChangeTheme: "#E2E2E2", // "#9aa1a5",
  colorAppName: "#727273", //"#363537",
  hoverColorAppName: "#363537", //"#727273",
  drawerBackground: "white",
  drawerItemColor: "#E2E2E2",
  drawerItemColorHover: "#9aa1a5",
  backgroundIcon: "rgba(0, 0, 0, 0.25)",
  colorIcon: "black",
  hoverBackground: "#9aa1a5", //"#727273",
  boxSearchBackground: "#F0F2F5",
  boxSearchHover: "#9aa1a5",
  borderColor: "#ced0d4",
  hoverItemAction: "#ced0d4",
  itemContentCenterBackground: "#fff",
  itemMaster: "#ffffff",
  hoverItemMaster: "rgba(0, 0, 0, 0.27) 0px 3px 7px 0px",
  rowTableHoverBackground: "#f8f8f8",
  rowTableBackground: "#fff",
  backgroundSkeleton: "#dddbdd",
  backgroundTableDetail: "#ffffff",
  backgroundTableDetailHeader: "rgba(0, 0, 0, 0.06)",
  backgroundIconMarker: "#fff",
  rowDataTableDetailHoverBackground: "#ebebeb",
  backgroundTableColHeader: "#ebebeb",
};

export const darkTheme = {
  body: "#303030", //"#363537",
  text: "#f3f6f9",
  headerBackground: "linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #1976D2, #1976D2);",
  btnChangeTheme: "#3f4041",
  colorAppName: "#92abcf",
  hoverColorAppName: "#11ece5",
  drawerBackground: "#727273",
  drawerItemColor: "#3f4041",
  drawerItemColorHover: "rgb(33, 44, 61)",
  backgroundIcon: "#727273",
  colorIcon: "white",
  hoverBackground: "#727273",
  boxSearchBackground: "#3f4041",
  boxSearchHover: "#727273",
  borderColor: "hsl(0deg 0% 100% / 10%)",
  hoverItemAction: "#3f4041",
  itemContentCenterBackground: "rgba(255, 255, 255, 0.1)", //"#22262e";
  itemMaster: "#424242",
  hoverItemMaster: "#363537 1px 1px 1px 1px",
  rowTableHoverBackground: "linear-gradient(45deg, rgba(25, 117, 117, 0.05) 0%, rgba(29, 184, 253, 0.08) 33%, rgba(48, 0, 106, 0.05) 100%)",
  rowTableBackground: "#424242",
  backgroundSkeleton: "#414247",
  backgroundTableDetail: "#424242",
  backgroundTableDetailHeader: "rgba(255, 255, 255, 0.12)",
  backgroundIconMarker: "rgba(0, 0, 0, 0.09)",
  rowDataTableDetailHoverBackground: "rgba(0, 0, 0, 0.1)",
  backgroundTableColHeader: "#727273",
};
