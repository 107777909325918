import React from "react";
import styled from "styled-components";
import * as _ from "lodash"

const TableComponent = (props) => {
    const {colHeader, listRows} = props;
    return (
        <TableContainer>
           <BoxTable>
                <Table>
                <Thead>
                    <TRHeader>
                        {_.map(colHeader, (col, index) => 
                            <ColHeader textAlign={index === 0 ? 'center' : 'left'} key={col.key}>{_.get(col, 'title', '')}</ColHeader>
                        )}
                    </TRHeader>
                </Thead>
                <TBody>
                    {_.map(listRows, (row, index) => 
                        <TRBody key={`rowBody${index}`}>
                            <ColBody textAlign='center'>{_.get(row, 'STT', '')}</ColBody>
                            <ColBody textAlign='left'>{_.get(row, 'station', '')}</ColBody>
                        </TRBody>
                    )}
                </TBody>
                </Table>
            </BoxTable>
        </TableContainer>
    )
}

const TableContainer = styled.div`
    overflow-y: auto;
    
`
const BoxTable = styled.div`
    width: 100%;
`
const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`

const Thead = styled.thead`
`

const TRHeader = styled.tr`
    margin: 3px 6px;
    background: #197575;
    background: linear-gradient(45deg, rgba(25, 117, 117, 0.05) 0%, rgba(29, 184, 253, 0.08) 33%, rgba(48, 0, 106, 0.05) 100%);
    // position: relative;
    display: grid;
    grid-template-columns: minmax(40px, 60px) minmax(120px, 260px) minmax(200px, 260px) minmax(150px, 260px) minmax(80px, 200px);
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    text-align: center;
    padding: 12px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: sticky;
    top: 0px;
    background-color: ${({ theme }) => theme.rowTableBackground};
`
const ColHeader = styled.th`
    font-weight: 700;
    text-align: ${props => props.textAlign};
`

const TBody = styled.tbody`
`

const TRBody = styled.tr`
    margin: 12px 6px;
    transform: none;
    transition: transform 0.2s ease-out, box-shadow 0.3s ease;
    display: grid;
    grid-template-columns: minmax(40px, 60px) minmax(120px, 260px) minmax(200px, 260px) minmax(150px, 260px) minmax(80px, 200px);
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    text-align: center;
    padding: 12px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: ${({ theme }) => theme.rowTableBackground};
    :hover {
        background: ${({ theme }) => theme.rowTableHoverBackground};
    }
`

const ColBody = styled.td`
    text-align: ${props => props.textAlign};
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
`



export default TableComponent