import React  from "react";
import styled from "styled-components";
import * as _  from 'lodash'
import './table-data.css'

const TableData = (props) => {
    const {data} = props
    const {cols, list} = data
    return (
        <TableBoxContainer className="table100 ver5 m-b-110">
            <TableBoxBody className="table100-body js-pscroll">
                <table>
                    <thead>
                        <HeaderRow>
                            {_.map(cols, (colHead) => 
                                <HeaderCol key={colHead.key} style={{...colHead.styleCol}}>
                                    {colHead.title}
                                </HeaderCol>
                            )}
                        </HeaderRow>
                    </thead>
                    <tbody>
                    {
                        _.size(list) > 0 ? _.map(list, (row) => 
                        <BodyRow key={row.id} >
                            {_.map(cols, (colHead)=> 
                                <BodyCol key={`${colHead.key}${row.id}`}  style={{...colHead.styleCol}}>
                                    {colHead.key === 'get_time' ? _.get(row, `${colHead.key}`, '-') : _.get(row, `${colHead.key}`, '-') === '-' ? '-' : _.round(_.get(row, `${colHead.key}`, '-'), 2)}
                                </BodyCol>
                            )}
                        </BodyRow>
                        ):
                        <div>Không có dữ liệu!</div>
                    }
                    </tbody>
                </table>
            </TableBoxBody>
        </TableBoxContainer>
    )
}


const TableBoxContainer = styled.div`
    width: 100%;
    height: calc((100%) - 60px);
    border: 1px ${({ theme }) => theme.borderColor} solid;
    border-radius: 5px;
    padding-right: 10px;
    padding-left: 10px;
`


const TableBoxBody = styled.div`
    max-height: calc((100%) - 30px);
    overflow: auto;
`

const BodyCol = styled.td`
    font-family: Lato-Regular;
    font-size: 15px;
    line-height: 1.4;
    background-color: ${({ theme }) => theme.backgroundTableDetailHeader};
`

const HeaderCol = styled.th`
    position: sticky;
    top: 0px;
    border: solid 1px transparent;
    border-style: solid none;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Lato-Regular;
    font-size: 15px;
    line-height: 1.4;
    background-color: ${({ theme }) => theme.backgroundTableColHeader};
`

const BodyRow = styled.tr`
    :hover {
        background-color: ${({ theme }) => theme.rowDataTableDetailHoverBackground};
        cursor: pointer;
    }
`

const HeaderRow = styled.tr`
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    :hover {
        background-color: ${({ theme }) => theme.rowDataTableDetailHoverBackground};
        cursor: pointer;
    }
`
export default TableData