import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {actionPost} from '../../utils/axios-action'
import * as _ from 'lodash'
import { useTranslation } from "react-i18next";
import Highcharts from 'highcharts';
import IconAQI from '../../assets/icon/AQI'
import IconWQI from '../../assets/icon/WQI'
import IconKTTV from '../../assets/icon/KTTV'
import IconCBL from '../../assets/icon/CBL'
import { lightTheme, darkTheme } from "../../constants/theme/theme";
import HighchartsReact from 'highcharts-react-official';
import SkeletonChartItem from '../skeleton/chart-item'
require("highcharts/modules/exporting")(Highcharts);




const ChartItemContainer = (props) => {
  const { t } = useTranslation();
  const currentTheme = props.currentTheme;
  const {screenHeight} = props
  const [dataChart, setDataChart] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const chartRef = useRef()

  useEffect(()=> {
    setIsLoading(true)
    fetchDataChart();
    return () => {
      /* componentWillUnmount code */
      console.log('componentWillUnmount==>ChartItemContainer')
    }
  }, [])

  // useEffect(()=> {
  //   // Highcharts.charts.forEach(chart => chart.reflow());
  //  // Highcharts.charts.forEach(chart => chart.setSize(null));
  // }, [dataChart])


  const fetchDataChart = async () => {
    try {
      const {links} = props.data
      const res = await actionPost(links.master, {})
      if(res.success) {
        setDataChart(res.data)
        // chartRef.setSize(null);
        Highcharts.charts.forEach(chart => chart.reflow());
        Highcharts.charts.forEach(chart => chart.setSize(null));
        setIsLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      setIsLoading(false)
    }
    
  }

  const renderOptions = () => {
    return  {
      chart: {
        type: 'column',
        backgroundColor: currentTheme === 'light' ? '#ffffff' : '#424242',
        // height: (6 / 16 * 100) + '%',
        height: (6 / 16 * 100) + '%'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: ''
        }
    
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%'
          }
        }
      },
    
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
      },
    
      series: dataChart,
    }
  }


  const renderIconTitle = (key) => {
    switch (key) {
      case 'AQI':
        return <IconAQI color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"32"} height={"32"}/>
      case 'WQI':
        return <IconWQI color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"26"} height={"26"}/>
      case 'KKTV':
        return <IconKTTV color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"26"} height={"26"}/>
      case 'CBL':
        return <IconCBL color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"26"} height={"26"}/>
      default:
        break;
    }
  }

  return isLoading ? (<SkeletonChartItem screenHeight={screenHeight}/>) : (<ItemBox height={`${(screenHeight-45)/2}px`}>
    <ItemBoxTitle>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
        {renderIconTitle(_.get(props, 'data.code', 'AQI'))}
        <Title>{_.get(props, 'data.name', ' ')}</Title>
      </div>
      <ViewDetail>
        <Link to={`/detail-map/${_.get(props, 'data.code', 'AQI')}`}>
        {t('DETAIL')}
        </Link>
      </ViewDetail>
    </ItemBoxTitle>
    <ChartBox>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={renderOptions()} />
    </ChartBox>
  </ItemBox> )
}
const ItemBox = styled.div`
  // display: inline-table;
  width: 48%;
  margin: 1%;
  height: ${props => props.height};
  padding: 10px;
  background-color: ${({ theme }) => theme.itemMaster};
  margin: 10px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  :hover {
    box-shadow: ${({ theme }) => theme.hoverItemMaster};
  }
  @media (max-width: 800px) {
    flex: 100%;
    display: inline-table;
  }
`
const ItemBoxTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #818181;
`
const Title = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.text};
`
const ViewDetail = styled.li`
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #1E90FF;
`
const ChartBox = styled.div`
  padding-top: 5px;
  height: 90%;
  width: 90%;
`

export default ChartItemContainer;