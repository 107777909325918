import React from "react";

const IconKTTV = (props) => {
  const {color, width, height} = props;
  return (
    <div style={{ display: "flex", fill: color }}>
      <svg id="Capa_1" enable-background="new 0 0 512 512" height={height} viewBox="0 0 512 512" width={width} xmlns="http://www.w3.org/2000/svg"><g><path d="m421 151c-.501 0-1.003.004-1.504.013-6.248-30.683-31.289-54.618-62.534-59.214-13.244-53.118-61.092-91.799-115.962-91.799-54.894 0-102.314 38.259-115.749 91.118-1.397-.076-2.813-.118-4.251-.118-66.72 0-121 53.832-121 120 0 66.212 54.547 121 121 121h300c49.659 0 91-41.153 91-91 0-49.52-41.033-90-91-90zm0 151h-300c-49.327 0-91-41.673-91-91 0-49.626 40.822-90 91-90 4.362 0 8.74.635 14.011 1.466 8.391 1.317 16.143-4.565 17.213-12.908 5.82-45.356 43.985-79.558 88.776-79.558 44.168 0 82.281 33.649 88.654 78.271 1.103 7.722 8.108 13.843 17.082 12.734 24.229.39 43.863 20.024 44.258 44.251-1.385 11.15 8.215 18.961 17.867 16.973 3.975-.815 8.059-1.229 12.139-1.229 33.636 0 61 26.916 61 60 0 33.065-27.935 61-61 61z"/><path d="m181 437c0-18.421-22.148-53.304-32.658-68.525-2.801-4.055-7.414-6.475-12.342-6.475s-9.541 2.42-12.342 6.475c-10.513 15.225-32.658 50.104-32.658 68.525 0 24.813 20.187 45 45 45s45-20.187 45-45zm-60 .018c.122-4.585 6.361-17.655 15-32.212 8.635 14.551 14.872 27.615 15 32.206-.007 8.266-6.733 14.988-15 14.988-8.271 0-15-6.729-15-14.982z"/><path d="m301 467c0-18.421-22.148-53.304-32.658-68.525-2.801-4.055-7.414-6.475-12.342-6.475s-9.541 2.42-12.342 6.475c-10.513 15.225-32.658 50.104-32.658 68.525 0 24.813 20.187 45 45 45s45-20.187 45-45zm-60 .018c.122-4.585 6.361-17.655 15-32.212 8.635 14.551 14.872 27.615 15 32.206-.007 8.266-6.733 14.988-15 14.988-8.271 0-15-6.729-15-14.982z"/><path d="m421 437c0-18.421-22.148-53.304-32.658-68.525-2.801-4.055-7.414-6.475-12.342-6.475s-9.541 2.42-12.342 6.475c-10.513 15.225-32.658 50.104-32.658 68.525 0 24.813 20.187 45 45 45s45-20.187 45-45zm-60 .018c.122-4.585 6.361-17.655 15-32.212 8.635 14.551 14.872 27.615 15 32.206-.007 8.266-6.733 14.988-15 14.988-8.271 0-15-6.729-15-14.982z"/></g></svg>
    </div>
  );
};

export default IconKTTV;
