import React from "react";

const IconAQI = (props) => {
  const {color, width, height} = props;
  return (
    <div style={{ display: "flex", fill: color }}>
      <svg width={width} height={height} viewBox="0 0 53 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9652 23.8002V22.8602L15.5238 22.7797L18.0106 15.9799H19.6166L22.0873 22.7797L22.6459 22.8602V23.8002H19.9818V22.8602L20.5243 22.7635L20.1805 21.6893H17.4305L17.0868 22.7635L17.6293 22.8602V23.8002H14.9652ZM17.8011 20.5345H19.8099L18.8969 17.8544L18.8217 17.618H18.7894L18.7089 17.8705L17.8011 20.5345ZM29.4886 25.3094L27.2811 23.8163C27.1415 23.8449 27 23.8682 26.8568 23.8861C26.7172 23.904 26.5775 23.913 26.4379 23.913C25.7325 23.913 25.1148 23.7429 24.5848 23.4027C24.0549 23.059 23.6413 22.5881 23.3441 21.9901C23.0505 21.3921 22.9037 20.7118 22.9037 19.9491V19.8309C22.9037 19.0718 23.0505 18.3933 23.3441 17.7953C23.6413 17.1973 24.0531 16.7264 24.5795 16.3827C25.1094 16.0389 25.7271 15.8671 26.4325 15.8671C27.1379 15.8671 27.7574 16.0389 28.2909 16.3827C28.828 16.7264 29.2452 17.1973 29.5424 17.7953C29.8431 18.3933 29.9935 19.0718 29.9935 19.8309V19.9491C29.9935 20.6294 29.87 21.2507 29.6229 21.8129C29.3794 22.375 29.0375 22.8334 28.597 23.1879L30.2621 24.2245L29.4886 25.3094ZM26.4379 22.6937C27.1003 22.6937 27.598 22.4431 27.931 21.9418C28.264 21.4369 28.4305 20.7727 28.4305 19.9491V19.8202C28.4305 19.2795 28.3553 18.8051 28.205 18.3969C28.0546 17.9851 27.8308 17.6646 27.5336 17.4354C27.2399 17.2063 26.8729 17.0917 26.4325 17.0917C25.7736 17.0917 25.2813 17.3423 24.9554 17.8436C24.6332 18.3449 24.472 19.0038 24.472 19.8202V19.9491C24.472 20.7727 24.635 21.4369 24.9608 21.9418C25.2902 22.4431 25.7826 22.6937 26.4379 22.6937ZM30.6327 23.8002V22.8602L31.4652 22.6991V17.0863L30.6327 16.9252V15.9799H33.8661V16.9252L33.0336 17.0863V22.6991L33.8661 22.8602V23.8002H30.6327Z" fill={color}/>
      <path d="M31.2905 0C38.3916 0 43.6921 5.44025 44.7521 11.7375C49.3196 13.6013 52.5632 18.1153 52.5632 23.4C52.5632 30.3374 46.9865 36 40.1541 36H12.3814C5.59736 36 0.563232 31.1819 0.563232 25.2C0.563232 20.6211 3.41632 16.1934 7.8203 14.775C8.2236 11.3313 9.96099 8.6071 12.363 7.14375C14.6462 5.75274 17.3982 5.47783 19.9524 6.2625C22.2361 2.74433 26.1959 0 31.2905 0ZM31.2905 3.6C26.931 3.6 23.7364 6.17186 22.2422 9.20625C22.0289 9.63114 21.6582 9.95271 21.2116 10.1004C20.765 10.248 20.2789 10.2097 19.8601 9.99375C18.0514 9.07549 15.8719 9.20225 14.1726 10.2375C12.4734 11.2728 11.1996 13.15 11.1996 16.2C11.1967 16.6232 11.047 17.0319 10.7769 17.3543C10.5067 17.6766 10.1333 17.8919 9.72232 17.9625C6.49241 18.5128 4.10869 21.8657 4.10869 25.2C4.10869 29.2137 7.14779 32.4 12.3814 32.4H40.1541C45.0704 32.4 49.0178 28.3918 49.0178 23.4C49.0178 19.3056 46.3428 15.8789 42.6839 14.775C42.3397 14.674 42.0337 14.4694 41.8062 14.1882C41.5788 13.9071 41.4406 13.5625 41.4098 13.2C41.0013 8.21966 36.9296 3.6 31.2905 3.6Z" fill={color}/>
      </svg>
    </div>
  );
};

export default IconAQI;
