import { DetailMapConstant} from "../../constants/actions";

export const setParams = (params) => ({
  type: DetailMapConstant.DETAIL_MAP_SET_PARAMS,
  params,
});

export const setParamsDone = (res) => ({
  type: DetailMapConstant.DETAIL_MAP_SET_PARAMS_DONE,
  res,
});
