import React, { useEffect, useRef } from 'react'
import { Card, Checkbox } from 'antd'
import 'ol/ol.css';
import { Map, View, Feature, Overlay } from 'ol'
import TileLayer from 'ol/layer/Tile';
import Point from 'ol/geom/Point';
import XYZ from 'ol/source/XYZ';
import TileWMS from 'ol/source/TileWMS'
import { Vector as VectorLayer } from 'ol/layer';
import { Fill, Stroke, Circle as CircleStyle, Style, Text, Icon } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import * as _ from 'lodash';
import './map.css'

const CENTER_POINT = [108.25241, 13.83453]//[105.034317, 22.749062361542362]

const MapComponent = (props) => {
    const map = useRef(null)
    const overlay = useRef(null)
    const lstStation = _.get(props, 'dataMap.list', [])

    const intMap = () => {
        map.current = new Map({
            target: document.getElementById('id-map'),
            layers: [
                // new TileLayer({
                //     title: 'VietBanDo',
                //     visible: true,
                //     type: 'base',
                //     source: new XYZ({
                //         attributions: 'Copyright:© 2021, Deahan-SI.,JSC, GeoEye',
                //         url: 'http://images.vietbando.com/ImageLoader/GetImage.ashx?Ver=2016&LayerIds=VBD&Level={z}&X={x}&Y={y}'
                //     })
                // }),
                new TileLayer({
                    visible: true,
                    id: 'province',
                    source: new TileWMS({
                        url: 'https://map.tnmtgialai.gov.vn/gialai/wms',
                        params: {
                            FORMAT: 'image/png',
                            tiled: true,
                            LAYERS: 'gialai:huyenthanhphothixa',
                            tilesOrigin: 104.15826297166633 + "," + 21.838597911674846
                        }
                    })
                }),

                new TileLayer({
                    visible: false,
                    id: 'province_zoom',
                    source: new TileWMS({
                        // crossOrigin: 'anonymous',
                        url: 'https://map.tnmtgialai.gov.vn/gialai/wms',
                        params: {
                            FORMAT: 'image/png',
                            tiled: true,
                            LAYERS: 'gialai:xaphuongthitran',
                            tilesOrigin: 104.15826297166633 + "," + 21.838597911674846
                        }
                    })
                }),
                new TileLayer({
                    visible: false,
                    id: 'songsuoiaoho',
                    title: 'Song suoi ao ho',
                    source: new TileWMS({
                        url: 'https://map.tnmtgialai.gov.vn/gialai/wms',
                        params: {
                            FORMAT: 'image/png',
                            tiled: true,
                            LAYERS: 'gialai:songsuoiaoho',
                            tilesOrigin: 108.179626 + "," + 13.904742
                        }
                    })
                })

            ],
            view: new View({
                center: CENTER_POINT,
                zoom: 10,
                minZoom: 2,
                maxZoom: 19,
                projection: 'EPSG:4326'
            }),
            overlays: [setPopup()]
        });

    }

    useEffect(() => {
        renderMap()
    }, [])

    const renderMap = () => {
        if (!map.current) {
            intMap()
        }
        getFeature(lstStation)
        map.current.render()
        handleZoomMap(map.current)
        setHandleClick()
        setClosePopup()
    }

    const handleZoomMap = (mapScr) => {
        // if (mapScr) {
        //     mapScr.on('moveend', function (evt) {
        //         let map = evt.map
        //         if (map) {
        //             const zoom = map.getView().getZoom();
        //             map.getLayers().forEach(function (layer) {
        //                 const idLayer = _.get(layer, 'values_.id')
        //                 if (_.isEqual(idLayer, 'province')) {
        //                     layer.values_.visible = zoom <= 10
        //                 } else if (_.isEqual(idLayer, 'province_zoom')) {
        //                     layer.values_.visible = zoom > 10
        //                 }
        //             });
        //         }
        //     })
        // }
    }

    // Get Featrure for map
    const getFeature = (data) => {
        setDataForMarker(data)
    }

    // Add station in marker
    const setDataForMarker = (data) => {
        _.forEach(data, (station) => {
            const geometry = new Point([station.longitude, station.latitude])
            addMarkerVector(geometry, station)
            addMarkerTitle(geometry, station.station_name)
        })
    }

    const addMarkerVector = (geometry, station) => {
        const marker = new Feature({ geometry, station })
        marker.setStyle(setStylePoint(_.get(station, 'level.color', 'DodgerBlue'), station.value, 15))

        let layerVector = new VectorLayer({
            source: new VectorSource({
                features: [marker],
                format: new GeoJSON({ "dataProjection": 'EPSG::4326', "featureProjection": 'EPSG::4236' })
            }),
            name: station.id
        })
        map.current.addLayer(layerVector)
    }

    const addMarkerTitle = (geometry, stationName) => {
        let markerTitle = new Feature({ geometry })
        markerTitle.setStyle(new Style({
            text: new Text({
                font: '14px sans-serif',
                offsetY: 24,
                text: stationName,
                fill: new Fill({ color: "#FFFFFF" }),
                backgroundFill: new Fill({ color: 'rgb(0, 0, 0, 0.5)' }),
                padding: [3, 5, 3, 5]
            })
        }))
        let layerTitle = new VectorLayer({
            source: new VectorSource({
                features: [markerTitle],
                format: new GeoJSON({ "dataProjection": 'EPSG::4326', "featureProjection": 'EPSG::4326' })
            }),
            name: 'IS_TITLE',
            visible: false
        })
        map.current.addLayer(layerTitle)
    }

    const setStylePoint = (color, value, width) => {
        return new Style({
            image: new CircleStyle({
                radius: 15,
                fill: new Fill({ color: color }),
                stroke: new Stroke({ color: `${color}60`, width })
            }),
            text: new Text({
                font: 'bold 14px sans-serif',
                text: `${value}`,
                fill: new Fill({
                    color: 'white'
                })
            })
        })
    }

    const setHandleClick = () => {
        map.current.on('singleclick', e => {
            if (map.current.hasFeatureAtPixel(e.pixel)) {
                let data = map.current.forEachFeatureAtPixel(e.pixel, f => f)
                var coordinate = e.coordinate;
                const { longitude, latitude, station_name, level, id, value, address } = data.values_.station
                document.getElementById('station-name').innerHTML = station_name
                document.getElementById('station-key').innerHTML = id
                document.getElementById('station-long').innerHTML = longitude
                document.getElementById('station-lat').innerHTML = latitude
                document.getElementById('station-address').innerHTML = address
                // document.getElementById('station-value').innerHTML = value
                overlay.current.setPosition(coordinate)
            }
        })
    }

    const setPopup = () => {
        overlay.current = new Overlay({
            element: document.getElementById('popup'),
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });
        return overlay.current
    }

    const setClosePopup = () => {
        let closer = document.getElementById('popup-closer');
        closer.onclick = () => {
            overlay.current.setPosition(undefined)
            closer.blur();
            return false;
        }
    }
    const onChange = (chekedValue) => {
        map.current.getLayers().forEach(function (layer) {
            const idLayer = _.get(layer, 'values_.id')
            if (_.isEqual(idLayer, chekedValue.target.value)) {
                layer.values_.visible = chekedValue.target.checked
            }
        });
        map.current.render()
    }

    return (
        <div id='id-map' style={{ width: '100%', height: `${props.heightMap + 70}px`, display: 'flex' }} >
            <div style={{ position: 'absolute', bottom: 48, right: 16, zIndex: 999 }}>
                <Checkbox value="province" defaultChecked onChange={onChange}>Quận huyện thị xã</Checkbox>
                <Checkbox value="province_zoom" onChange={onChange}>Xã phường thị trấn</Checkbox>
                <Checkbox value="songsuoiaoho" onChange={onChange}>Sông suối ao hồ</Checkbox>
            </div>

            <div id="popup" class="ol-popup">
                <a href="#" id="popup-closer" class="ol-popup-closer"></a>
                <div id="popup-content">
                    <Popup />
                </div>
            </div>

        </div>
    )
}

const Popup = () => {
    return (<Card bordered title={<span id='station-name'></span>} style={{ width: 400, height: '100%', border: '1px solid #b9adad', borderRadius: 10, fontFamily: 'sans-serif' }}>
        <p><b>Mã điểm: &nbsp; </b><span id='station-key'></span></p>
        <p><b>Kinh độ: &nbsp;</b><span id='station-long'></span> - <b>Vĩ độ: &nbsp;</b><span id='station-lat'></span></p>
        <p><b>Địa chỉ: &nbsp; </b><span id='station-address'></span></p>
        {/* <p><b>Chỉ số AQI: &nbsp;</b><span id='station-value'></span></p> */}
    </Card>
    )
}

export { MapComponent }
