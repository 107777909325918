import React  from "react";
import styled from "styled-components";
import "./detail-table.css";


const SkeletonDetailTable = () => {
    return (
        <BoxContainer>
            <ItemBox key={'skeleton_detailtable_1'}>
                <ItemSkeleton className="detai-table-skeleton-box" style={{width:'100%', height: '100%', borderRadius:'16px'}}></ItemSkeleton>
            </ItemBox>
        </BoxContainer>
    )
}
const BoxContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    width: 100%;
    height: 100%;
    height: calc((100%) - 70px);
`

const ItemBox = styled.div`
  width: calc(((100%) - 40px));
  background-color: ${({ theme }) => theme.itemMaster};
  margin: 20px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  :hover {
    box-shadow: ${({ theme }) => theme.hoverItemMaster};
  }
  @media (max-width: 800px) {
    flex: 100%;
  }
`

const ItemSkeleton = styled.div`
    display: inline-block;
    height: 21em;
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundSkeleton};
`

export default SkeletonDetailTable