import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as detailMapAction from '../../redux/actions'
import * as _ from 'lodash'
import './list-station.css'

const ListStation = (props) => {
    const {heightScreen, dataMap , apiDetail, type} = props
    const history = useHistory();
    const [widthBoxLevel, setWidthBoxLevel] = useState(null)
    const widtLevelContainer = useRef()

    console.log('ListStation==>', type)

    useEffect(() => {
        setWidthBoxLevel(widtLevelContainer.current.clientWidth)
        return () => {
        }
    },[])


    // Redirect to detail table 
    const handleRowClick = (row) => {
        const id = row.id
        if (!_.isEmpty(id)) {
            props.actionSetParamsDetailTable({...row, api: apiDetail, typeMaster: type})
            history.push(`/detail-table/${id}`);
        }
    }


    // Render table list station 
    const renderTableStationList = (dataTable) => {
        const {cols, list} = dataTable
        return (
            <TableBoxContainer className="tableAQI ver6">
                <TableBoxHeader className="tableAQI-head">
                    <table>
                        <thead>
                            <BodyRow className="row100 head">
                                {_.map(cols, (colHead) => 
                                    <ColHeader key={colHead.key} style={{...colHead.styleCol}}>
                                        <TitleColHeader style={{...colHead.styleTitle}}>{colHead.title}</TitleColHeader>
                                    </ColHeader>
                                )}
                            </BodyRow>
                        </thead>
                    </table>
                </TableBoxHeader>
                <TableBoxBody className="tableAQI-body js-pscroll">
                        <table>
                            <tbody>
                                {
                                    _.size(list) > 0 ? _.map(list, (row) => 
                                    <BodyRow key={row.id} >
                                        {_.map(cols, (colHead)=> 
                                            <BodyCol onClick={() => handleRowClick(row)} key={`${colHead.key}${row.id}`}  style={{...colHead.styleCol}}>
                                                <TitleColBody style={{...colHead.styleTitle}}>
                                                    {type === 'AQI' || type === 'WQI' ? (colHead.key === 'value' ? <ColorLevelCircle color={_.get(row, 'level.color', 'DodgerBlue')}>{_.get(row, `${colHead.key}`, '')}</ColorLevelCircle> : _.get(row, `${colHead.key}`, '')) : ( colHead.key === 'value' ? <ColorLevelBorder color={_.get(row, 'level.color', 'DodgerBlue')}>{_.get(row, `${colHead.key}`, '')}</ColorLevelBorder> : _.get(row, `${colHead.key}`, ''))}
                                                    {/* {colHead.key === 'value' ? <ColorLevelCircle color={_.get(row, 'level.color', 'DodgerBlue')}>{_.get(row, `${colHead.key}`, '')}</ColorLevelCircle> : _.get(row, `${colHead.key}`, '')} */}
                                                </TitleColBody>
                                            </BodyCol>
                                        )}
                                    </BodyRow>
                                    ):
                                    <div>Không có dữ liệu!</div>
                                }
                            </tbody>
                        </table>
                    </TableBoxBody>
            </TableBoxContainer>
        )
    }

    // render item level
    const renderItemLevel = (widthBoxLevel, dataLevel) => {
        if (dataLevel.length > 0) {
            const numberLevel = dataLevel.length
            const widthItem = widthBoxLevel/numberLevel
            const lstLevelOrderby = _.orderBy(dataLevel, 'order', 'asc')
            return _.map(lstLevelOrderby, (item) => 
                <ItemLevel key={item.key} width={`${widthItem}px`} color={item.color} style={{...renderBorderRadiusLevelItem(item.order, 1, numberLevel)}}>
                    <TitleItemLevel>{item.name}</TitleItemLevel>
                    {!_.isEmpty(_.get(item, 'level', null)) ? <TitleItemLevel>{`(${item.level})`}</TitleItemLevel> : <></>}
                </ItemLevel>
            )
        }
        return <></>
    }
    
    // Render border radius for item level start, end
    const renderBorderRadiusLevelItem = (position, positionStart, positionEnd) => {
        const styleBorderLeft = {borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}
        const styleBorderRight = {borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}
        if(position === positionStart) {
            return styleBorderLeft
        }
        if(position === positionEnd) {
            return styleBorderRight
        }
        return {}
    }

    return(
        <ListContainer style={{height: heightScreen + 70}}>
            {dataMap && renderTableStationList(dataMap)}
            <LevelContainer ref={widtLevelContainer}>
                {widthBoxLevel && renderItemLevel(widthBoxLevel, _.get(dataMap, 'level'))}
            </LevelContainer>
        </ListContainer>
        
    )
}

const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 5px;
`

const LevelContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    height: 8%;
    border-radius: 5px;
`
const ItemLevel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: 100%;
    background-color: ${props => props.color};
`

const TitleItemLevel = styled.div`
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    font-family: sans-serif;
`

const TableBoxContainer = styled.div`
    height: 90%;
    width: 100%;
    border: 1px ${({ theme }) => theme.borderColor} solid;
    border-radius: 5px;
    padding-right: 10px;
    padding-left: 10px;
`

const TableBoxHeader = styled.div`
    position: absolute;
    width: calc((100%) - 20px);
    top: 0;
    left: 0;
    left: 10px;
    right: 10px;
    top: 10px;
    background-color: ${({ theme }) => theme.backgroundTableDetailHeader};
    border-radius: 10px;
`

const TableBoxBody = styled.div`
    max-height: calc((100%) - 30px);
    overflow: auto;
`

const BodyCol = styled.td`
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    background-color: ${({ theme }) => theme.backgroundTableDetailHeader};
`

const TitleColBody = styled.div`
    display: flex;
    justify-content: ${props => props.floatContent};
`

const BodyRow = styled.tr`
    :hover {
        background-color: ${({ theme }) => theme.rowDataTableDetailHoverBackground};
        cursor: pointer;
    }
`

const ColHeader = styled.th`
`

const TitleColHeader = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 1.5;
    font-weight: bold;
    font-family: sans-serif;
`

const ColorLevelCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.color};
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    height: 40px;
    width: 40px;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    font-family: sans-serif;
`
const ColorLevelBorder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.color};
    color: #fff;
    border-radius: 10px;
    padding: 5px;
    height: 30px;
    min-width: 40px;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    font-family: sans-serif;
`
const mapStateToProps = (state) => {
    return {
      ...state,
    };
  };

const mapDispatchToProps = (dispatch) => {
    return {
      actionSetParamsDetailTable: (params) => dispatch(detailMapAction.setParams(params)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(ListStation); 