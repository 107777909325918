import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {actionPost} from '../../utils/axios-action'
import SkeletonTableItem from '../skeleton/table-item'
import TableComponent from '../../component/table'
import IconCBL from '../../assets/icon/CBL'
import { lightTheme, darkTheme } from "../../constants/theme/theme";
import * as _ from "lodash";


const TableItemContainer = props => {
  const { t } = useTranslation();
  const currentTheme = props.currentTheme;
  const {screenHeight} = props
  const [isLoading, setIsLoading] = useState(true)
  const [dataTable, setDataTable] = useState([])
  const [cols, setCols] = useState([])

  useEffect(()=> {
    setIsLoading(true)
    fetchDataTable();
    return () => {
      /* componentWillUnmount code */
      console.log('componentWillUnmount==>TableItemContainer')
    }
  }, [])


  const fetchDataTable = async () => {
    try {
      const {links} = props.data
      const res = await actionPost(links.master, {})
      if(res.success) {
        setDataTable(_.get(res,'data.list', []))
        setCols(_.get(res,'data.cols', []))
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log('error')
    }
  }


  return isLoading ? (<SkeletonTableItem screenHeight={screenHeight}/>) : (
      <ItemBox height={`${(screenHeight-45)/2}px`}>
        <ItemBoxTitle>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
            <IconCBL color={currentTheme === "light" ? lightTheme.colorIcon : darkTheme.colorIcon} width={"26"} height={"26"}/>
            <Title>{_.get(props, 'data.name', '')}</Title>
          </div>
          <ViewDetail>
            <Link to={`/detail-map/${_.get(props, 'data.code', 'AQI')}`}>
            {t('DETAIL')}
            </Link>
          </ViewDetail>
        </ItemBoxTitle>
        <BoxTable>
          <TableComponent colHeader={cols} listRows={dataTable}/> 
        </BoxTable>
      </ItemBox>
  )
}

const ItemBox = styled.div`
  padding: 10px;
  width: 48%;
  margin: 1%;
  height: ${props => props.height};
  background-color: ${({ theme }) => theme.itemMaster};
  margin: 10px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  :hover {
    box-shadow: ${({ theme }) => theme.hoverItemMaster};
  }
  @media (max-width: 800px) {
    flex: 100%;
  }
`
const ItemBoxTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #818181;
`
const Title = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.text};
`
const ViewDetail = styled.li`
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #1E90FF;

`
const BoxTable = styled.div`
  display: flex;
  margin-top: 5px;
  overflow: scroll;
  height: 90%;
  width: 100%;
`

export default TableItemContainer;