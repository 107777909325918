import { MasterConstant } from "../../constants/actions";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
};

const master = (state = initialState, action) => {
  switch (action.type) {
    case MasterConstant.MASTER_GET_DATA:
      return { ...state, isLoading: true };
    case MasterConstant.MASTER_GET_DATA_SUCCESS:
      return { ...state, isLoading: false, data: action.res };
    case MasterConstant.MASTER_GET_DATA_ERROR:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default master;
